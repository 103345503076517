// import { MDBContainer } from "mdbreact";
import React from "react";
import "./home.css";

function Home() {
return (
    <div className="bg">
        
      </div>
) 
}

export default Home;